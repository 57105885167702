<!-- 直播 -->
<template>
  <div class="v-container">
    <!-- 顶部标题 -->
    <div class="sub-container">
      <div class="v-title">
        <div class="v-title-left">{{ nowTime }}</div>
        <!-- <div :class="{ editing: isChecked }">可视化案场</div> -->
        <div id="test" class="test" :class="{ editing: isChecked }">
          <div class="view">
            <label @dblclick="dbTest()">{{ TitleName }}</label>
          </div>
          <input
            v-myfoucs="isChecked"
            class="edit"
            type="text"
            v-model="inputStr"
            @blur="inputStred()"
            @keyup.13="inputStred()"
          />
        </div>
        <div class="v-title-right2">
          <div>
            <span @click="clickFun" class="quanping">
              <i class="iconfont icon-quanping">
                {{ isFullFlag ? "退出全屏" : "全屏" }}
              </i></span
            >
          </div>
        </div>
      </div>
    </div>
    <!-- 轮播 -->
    <div class="swiper-container swiper-no-swiping" :id="currentIndex">
      <!-- <div class="swiper-container" :id="currentIndex"> -->
      <div class="swiper-wrapper">
        <div v-for="(item, i) in record" :key="i" class="swiper-slide">
          <div style="width: 100%; height: 100%">
            <div class="myVideo_out">
              <div
                class="myVideo_out1"
                @mouseenter="ShowCanceSampleSiteBTN(i, 'block', 'true')"
                @mouseleave="ShowCanceSampleSiteBTN(i, 'none', 'false')"
              >
                <video
                  autoplay
                  controls
                  width="97%"
                  height="92%"
                  class="myVideo"
                  :id="'myVideo' + i"
                  crossorigin="*"
                  style="background: black; object-fit: cover"
                  :poster="posterImg"
                ></video>
                <div class="v-box-info">
                  <div class="v-box-title">
                    {{
                      item.ws_name
                        .replace(/直辖市|-北京|-天津/g, "")
                        .replace(/[0-9]/g, "*")
                    }}
                  </div>
                  <div>
                    <span>
                      {{
                        "工程管家：" + (item.w_name_gj ? item.w_name_gj : "")
                      }}</span
                    >
                    <span class="w_name_jl_left">
                      {{
                        "项目经理：" + (item.w_name_jl ? item.w_name_jl : "")
                      }}</span
                    >
                  </div>
                </div>
                <div class="controlBtn">
                  <div
                    :id="'CanceSampleSiteBtn' + i"
                    style="display: none; padding: 2px 2px; font-weight: 800"
                    @click="CanceSampleSite(item.ws_id, 1, i)"
                    v-if="
                      isShowBtns.indexOf('customPreview-/api/worksite/model') >
                      -1
                    "
                  >
                    取消样板工地
                  </div>
                </div>
                <!-- 已取消弹出框提示 -->
                <div class="Cance_ed">
                  <div
                    :id="'Cance_ed' + i"
                    style="
                      display: none;
                      background-color: rgba(0, 0, 0, 0.4);
                      padding: 10px 20px;
                    "
                  >
                    已取消
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- 如果需要分页器 -->
      <!-- <div class="swiper-pagination"></div> -->
      <!-- 如果需要导航按钮 -->
      <!-- <div class="swiper-button-prev"></div> -->
      <!-- <div class="swiper-button-next"></div> -->
    </div>
  </div>
</template>

<script>
import {
  GetSiteVideo,
  StopSiteVideo,
  OnCall,
  OffCall,
  CameraMove,
  CameraReset,
  CameraRestart,
  CameraStatus,
  GetWsbinddevices,
  GBVideoLive,
  GBVideoUnlive,
} from "@/api/video";
import { SetSampleSite } from "@/api/site";
import flvjs from "flv.js";
import LineImage from "../datav/LineImage.vue";
import { urlConfig } from "@/config/config";
import screenfull from "screenfull";
import Swiper from "swiper"; // 注意引入的是Swiper
import "swiper/css/swiper.min.css"; // 注意这里的引入
import { GetServiceList } from "@/api/site"; //获取工地列表

export default {
  components: {
    LineImage,
  },
  name: "customPreviewView", // 直播
  // name: "customPreview", // 直播

  ws: null,
  soundrecord: null,
  data() {
    return {
      timer: null,
      ws_id: "",
      d_id: "",
      d_idClose: "",
      video: {
        com_only: "",
        video_url: "",
        audio_url: "",
        stream_id: "",
        push_stream_id: "",
      },
      voiceCall: false,
      cycleMax: 15,
      cycleTime: 1,
      flvPlayer: null,
      // 弹出框
      visible: false,
      confirmLoading: false,
      wsModel: "0",
      type: "2",
      typecaseField: "2",
      ModelcaseField: "2",
      // stream_url: "webrtc://dsm.wisecommey.com:1990/stream/",
      stream_url: "webrtc://dsm.ccom.com.cn:1990/stream/",

      Video_Status: "",
      xinhao: null,
      NoXinhao: false,
      jingyin: 1,
      bofang: 2,
      signalResData2: "",
      getvideoSignalTimer: null,
      ModalText: "Content of the modal",
      recordwsid: "",
      posterImg: "",
      ComeonlyWsid: [],
      isSampleSite: true,
      resData: "",
      TitleName: "可视化案场",
      inputStr: "",
      isChecked: false,
      isFullFlag: false,
      current: 1,
      total: 0,
      queryParam: {
        ws_id: "",
        bc_id: "",
        ws_bind_status: "2",
        ws_status: "1",
        d_status: "1",
        ws_model: "1",
        pageNo: 1,
        pageSize: 100,
        // pageSize: 1,
      },
      nowTime: "", // 当前日期时间
      record: {},
      uidID: "",
      recordSlice: "",
      record_length: "",
      record_interval: null,
      record_ten_interval: null,
      currentIndex: 1,
      recordClose_interval: null,
      ComeonlyWsid_close: [],
      record_lessThan_ten: [],
      record_lessThan_ten_interval: null,
      record_i: 10,
      index: 9,
      record_close: 0,
      index_close: 0,
      isShowBtns: [],
      liveImei: "",
      liveImeiList: [],
      gbVideoValue: {},
      startPlayGBSIndex: [], //gbs摄像机直播的id index
    };
  },
  created() {
    this.GetSiteList();
    // 初始化数据 触发监听  data是props
    setTimeout(() => {
      //   this.record_ten();
      //   setTimeout(() => {
      //     this.initSwiper();
      //   }, 100);
      // 只有前几个 要改进
      // for (let el = 0; el < 10; el++) {
      //   this.ComeonlyWsid.forEach((el) => {
      //     this.ComeonlyWsid_close.push(el);
      //   });
      // }
      // console.log(this.ComeonlyWsid_close);
    }, 1500);
  },

  mounted() {
    window.onunload = function (e) {
      window.clearTimeout(this.timer); // 清理定时器
      this.close();
    };
    window.addEventListener("beforeunload", (e) => {
      e = e || window.event;
      if (e) {
        clearInterval(this.record_interval);
        this.close();
        clearInterval(this.record_ten_interval);
        clearInterval(this.recordClose_interval);
        clearInterval(this.record_lessThan_ten_interval);
      }
    });
    // 10秒后清除获取信号值的定时器
    setTimeout(() => {
      clearInterval(this.getvideoSignalTimer);
      this.getvideoSignalTimer = null;
    }, 10000);
    // 轮播
    this.getTime();
    // 如果本地存的有标题，就用本地存的标题
    if (localStorage.getItem("CaseFieldView_TitleName")) {
      this.TitleName = localStorage.getItem("CaseFieldView_TitleName");
    }
    this.isShowBtns = sessionStorage.getItem("isShowBtns").split(",");
    // 轮播
  },
  watch: {
    record(NValue, OValue) {
      setTimeout(() => {
        this.record_ten();
        setTimeout(() => {
          this.initSwiper();
        }, 100);
      });
      // if (NValue.length > 4) {
      // console.log(this.record.length);
      // if (NValue.length > 10) {
      //   // 一分钟分钟对record进行一次截取
      //   // let record_i = 4;
      //   let record_i = 10;
      //   // let index = 3;
      //   let index = 9;
      // let record_close = 0;
      // let index_close = 0;
      //   // console.log(this.ComeonlyWsid_close);
      //   // 隔一分钟开启两个直播
      //   this.record_interval = setInterval(() => {
      //     this.recordSlice = this.record.slice(record_i, record_i + 2);
      //     this.recordSlice.forEach((item, i) => {
      //       index++;
      //       this.ws_id = item.ws_id;
      //       this.getVideo(index);
      //       console.log(item.ws_id);
      //     });
      //     record_i = record_i + 2;
      //   }, 1000 * 60);
      //   // }, 1000 * 30);

      //   // 结束直播
      //   this.recordClose_interval = setInterval(() => {
      //     this.ComeonlyWsid_close.forEach((el) => {
      //       console.log("ComeonlyWsid_close=", el.ws_id);
      //     });
      //     let recordClose = this.ComeonlyWsid_close.slice(
      //       record_close,
      //       record_close + 2
      //     );
      //     recordClose.forEach((el) => {
      //       index_close++;
      //       // console.log("el.ws_id", el.ws_id);
      //       // console.log("record_close", record_close);
      //       const params = {
      //         ws_id: el.ws_id,
      //         com_only: el.com_only,
      //       };
      //       if (this.publisher) {
      //         this.publisher.close();
      //       }
      //       if (this.flvPlayer) {
      //         clearInterval(this.timeout);
      //         this.flvPlayer.unload(); //取消数据流加载
      //         this.flvPlayer.detachMediaElement(); //将播放实例从节点中取出
      //         this.flvPlayer.destroy(); //销毁播放实例
      //         this.flvPlayer = null;
      //       }
      //       StopSiteVideo(params)
      //         .then((res) => {
      //           // this.$message.success("断开视频成功");
      //         })
      //         .catch(() => {
      //           // this.$message.error("断开视频失败");
      //         });
      //     });
      //     record_close = record_close + 2;
      //   }, 1000 * 59);

      //   // 到达一定的时间之后，record从0开始循环拉流
      let r_length = this.record.length - 1;
      console.log(r_length);
      // setTimeout(() => {
      if (this.record_i == r_length) {
        console.log("setTimeout", record_i);
        // record_i = 4;
        // index = 3;
        this.record_i = 10;
        this.index = 9;
        this.record_close = 0;
        this.index_close = 0;
        this.ComeonlyWsid = [];
        this.record_ten();
      }

      // }, 1000 * 60 * r_length);
      // }, 1000 * 10);
      // } else {
      //   this.record_lessThan_ten.forEach((el, i) => {
      //     this.ws_id = item.ws_id;
      //     this.getVideo(i);
      //   });
      //   this.record_lessThan_ten_interval = setInterval(() => {
      //     this.record_lessThan_ten.forEach((el, i) => {
      //       this.ws_id = item.ws_id;
      //       this.getVideo(i);
      //     });
      //   }, 1000 * 60 * 5);
      // }
    },
    ComeonlyWsid(NV, OV) {
      this.ComeonlyWsid_close = NV;
      console.log("ComeonlyWsid", NV);
    },
    immediate: true,
  },
  methods: {
    // 获取工地列表
    GetSiteList() {
      let orderParam = [
        "ws_name",
        "w_name",
        "w_gj_name",
        "bc_name",
        "nowork",
        "ws_bind_time_start",
        "ws_bind_time_end",
        "ws_bind_status",
        "ws_status",
        "ws_service_type",
        "ws_attendance",
        "ws_only",
        "d_status",
        "ws_dispatch",
        "bc_id",
        "cs_id",
        "d_imei",
        "pageNo",
        "pageSize",
      ];
      const params = {
        ...this.queryParam,
        verify: this.$verify(this.queryParam, orderParam),
      };
      GetServiceList(params).then((res) => {
        // console.log(res.data.data);
        // this.record = res.data.data;
        this.total = res.data.sum;
        let record_one = [];
        if (res.data.data.length > 6) {
          for (let el = 0; el < 6; el++) {
            res.data.data.forEach((el) => {
              record_one.push(el);
            });
          }
          this.record = record_one;
          this.record_lessThan_ten = res.data.data;
        } else {
          this.record = res.data.data;
        }

        // console.log(res.data.data);

        // let record_two = [];
        // setTimeout(() => {
        //   console.log(record_one);
        //   for (let index = 0; index < record_one.length; index = index + 2) {
        //     // record_two.push(res.data.data.slice(index, index + 2));
        //     record_two.push(record_one.slice(index, index + 2));
        //     // console.log(index, index + 2); // 0 2 4
        //   }
        //   console.log(record_two);
        //   this.record = record_two;
        // }, 10);

        this.$nextTick(() => {
          // this.initSwiper();
        });
      });
    },
    // 查询工地绑定设备信息
    GetWsbinddevices(index, ws_id) {
      const param = {
        ws_id: ws_id,
      };
      const orderParam = ["ws_id"];
      const requestParameters = this.$order(param, orderParam);
      const params = {
        ...requestParameters,
        verify: this.$verify(requestParameters, orderParam),
      };
      return GetWsbinddevices(params).then((res) => {
        let ws_bind_d_ids = res.data;
        let index1 = 0;
        // console.log("index", index, ws_bind_d_ids);
        ws_bind_d_ids.forEach((el) => {
          if (el.ddefault == 1) {
            this.ws_id = el.dwsId;
            this.d_id = el.did;
            // this.getVideo(index);

            this.liveImei = el.dimei;
            if (el.dplatform == "htx") {
              this.getVideo(index);
            } else if (el.dplatform == "gbs") {
              this.getVideoGBS(index);
              this.liveImeiList.push(el.dimei);
            }
          } else {
            index1++;
            if (index1 == ws_bind_d_ids.length) {
              this.ws_id = ws_bind_d_ids[0].dwsId;
              this.d_id = ws_bind_d_ids[0].did;

              // this.getVideo(index);
              console.log("index", index);

              if (ws_bind_d_ids[0].dplatform == "htx") {
                this.getVideo(index);
              } else if (ws_bind_d_ids[0].dplatform == "gbs") {
                console.log("gbs222");
                this.getVideoGBS(index);
                this.liveImei = ws_bind_d_ids[0].dimei;
                this.liveImeiList.push(ws_bind_d_ids[0].dimei);
              }
            }
          }
        });
      });
    },
    // 获取默认did
    GetWsbinddevices_did(ws_id, el) {
      const param = {
        ws_id: ws_id,
      };
      let d_idClose = "";
      const orderParam = ["ws_id"];
      const requestParameters = this.$order(param, orderParam);
      const params = {
        ...requestParameters,
        verify: this.$verify(requestParameters, orderParam),
      };
      return GetWsbinddevices(params)
        .then((res) => {
          let ws_bind_d_ids = res.data;
          let index1 = 0;
          ws_bind_d_ids.forEach((el) => {
            if (el.ddefault == 1) {
              d_idClose = el.did;
              this.liveImei = el.dimei;
            } else {
              index1++;
              if (index1 == ws_bind_d_ids.length) {
                d_idClose = ws_bind_d_ids[0].did;
                this.liveImei = ws_bind_d_ids[0].dimei;
              }
            }
          });
        })
        .then(() => {
          // htx关闭直播
          const params = {
            ws_id: ws_id,
            d_id: d_idClose,
            com_only: el.com_only,
          };
          if (this.publisher) {
            this.publisher.close();
          }
          if (this.flvPlayer) {
            clearInterval(this.timeout);
            this.flvPlayer.unload(); //取消数据流加载
            this.flvPlayer.detachMediaElement(); //将播放实例从节点中取出
            this.flvPlayer.destroy(); //销毁播放实例
            this.flvPlayer = null;
          }
          StopSiteVideo(params).then((res) => {});
          this.stopVideo(ws_id, d_idClose, el.com_only);
          // gbs关闭直播
          this.startPlayGBSIndex.forEach((el) => {
            this.VideoUnliveGBS(el);
          });
        });
    },
    initSwiper() {
      let vm = this;
      // currentIndex
      // var mySwiper = new Swiper(".swiper-container", {
      var mySwiper = new Swiper("#" + vm.currentIndex, {
        // loop: true, // 循环模式选项 当播放到最后一张图片后，点击按钮是否会继续轮播
        // slidesPerView: 2, //一次显示4个
        slidesPerView: 5, //一次显示10个
        slidesPerGroup: 1, //一次往前移一个
        slidesPerColumn: 2, //多行 —— 一屏显示2行
        // loopedSlides: 1,
        // loopFillGroupWithBlank: true,
        observer: true, //修改swiper自己或子元素时，自动初始化swiper
        observeParents: true, //修改swiper的父元素时，自动初始化swiper
        autoplay: true,
        autoplay: {
          delay: 1000 * 60, // 自动播放的间隔时间，单位ms，默认3000ms
          // delay: 1000 * 30,
          disableOnInteraction: false, // 用户操作后是否停止自动播放，默认为true，即停止。改为false，表示不会停止自动播放
        },
        // 如果需要前进后退按钮
        navigation: {
          nextEl: ".swiper-button-next",
          prevEl: ".swiper-button-prev",
        },
        // 如果需要分页器 即轮播图下面的小圆点
        // pagination: {
        //   el: ".swiper-pagination",
        // },
        on: {
          slideChangeTransitionEnd: function () {
            // vm.currentIndex;
            if (vm.record.length > 10) {
              // 一分钟分钟对record进行一次截取

              // let record_i = 10;

              // let index = 9;
              // let record_close = 0;
              // let index_close = 0;
              // 隔一分钟开启两个直播
              // vm.record_interval = setInterval(() => {
              vm.recordSlice = vm.record.slice(vm.record_i, vm.record_i + 2);
              vm.recordSlice.forEach((item, i) => {
                vm.index++;
                vm.ws_id = item.ws_id;
                // vm.d_id = item.ws_bind_d_ids.split(",")[0];
                // vm.getVideo(vm.index);
                this.GetWsbinddevices(vm.index, vm.ws_id);
              });
              vm.record_i = vm.record_i + 2;
              // }, 1000 * 60);
              // }, 1000 * 30);

              // 结束直播
              // this.recordClose_interval = setInterval(() => {
              vm.ComeonlyWsid_close.forEach((el) => {});
              let recordClose = vm.ComeonlyWsid_close.slice(
                vm.record_close,
                vm.record_close + 2
              );
              recordClose.forEach((el) => {
                vm.index_close++;
                vm.GetWsbinddevices_did(el.ws_id, el);
                // setTimeout(() => {
                //   const params = {
                //     ws_id: el.ws_id,
                //     // d_id: el.ws_bind_d_ids.split(",")[0],
                //     d_id: vm.d_idClose,
                //     com_only: el.com_only,
                //   };
                //   if (vm.publisher) {
                //     vm.publisher.close();
                //   }
                //   if (vm.flvPlayer) {
                //     clearInterval(vm.timeout);
                //     vm.flvPlayer.unload(); //取消数据流加载
                //     vm.flvPlayer.detachMediaElement(); //将播放实例从节点中取出
                //     vm.flvPlayer.destroy(); //销毁播放实例
                //     vm.flvPlayer = null;
                //   }
                //   StopSiteVideo(params)
                //     .then((res) => {
                //       // vm.$message.success("断开视频成功");
                //     })
                //     .catch(() => {
                //       // vm.$message.error("断开视频失败");
                //     });
                // });
              });
              vm.record_close = vm.record_close + 2;
              // }, 1000 * 60);

              // 到达一定的时间之后，record从0开始循环拉流
              // let r_length = this.record.length / 2;
              // setTimeout(() => {
              //   // record_i = 4;
              //   // index = 3;
              //   record_i = 10;
              //   index = 9;
              //   record_close = 0;
              //   index_close = 0;
              //   this.ComeonlyWsid = [];
              //   this.record_ten();
              // }, 1000 * 60 * r_length);
              // }, 1000 * 10);
            } else {
              // this.record_lessThan_ten.forEach((el, i) => {
              //   this.ws_id = item.ws_id;
              //   this.getVideo(i);
              // });
              // this.record_lessThan_ten_interval = setInterval(() => {
              //   this.record_lessThan_ten.forEach((el, i) => {
              //     this.ws_id = item.ws_id;
              //     this.getVideo(i);
              //   });
              // }, 1000 * 60 * 5);
            }
          },
        },
      });
    },
    // 循环record，在created里拉流
    record_ten() {
      this.record.slice(0, 2).forEach((item, i) => {
        if (item.ws_id) {
          this.ws_id = item.ws_id;
          this.GetWsbinddevices(i, this.ws_id);
          // this.d_id = item.ws_bind_d_ids.split(",")[0];
          // this.getVideo(i);
        }
      });
      setTimeout(() => {
        this.record.slice(2, 4).forEach((el, index) => {
          if (el.ws_id) {
            this.ws_id = el.ws_id;
            this.GetWsbinddevices(index + 2, this.ws_id);
            // this.d_id = el.ws_bind_d_ids.split(",")[0];
            // this.getVideo(index + 2);
          }
        });
      }, 10);
      setTimeout(() => {
        this.record.slice(4, 6).forEach((el, index) => {
          if (el.ws_id) {
            this.ws_id = el.ws_id;
            this.GetWsbinddevices(index + 4, this.ws_id);
            // this.d_id = el.ws_bind_d_ids.split(",")[0];
            // this.getVideo(index + 4);
          }
        });
      }, 20);
      setTimeout(() => {
        this.record.slice(6, 8).forEach((el, index) => {
          if (el.ws_id) {
            this.ws_id = el.ws_id;
            this.GetWsbinddevices(index + 6, this.ws_id);

            // this.d_id = el.ws_bind_d_ids.split(",")[0];
            // this.getVideo(index + 6);
          }
        });
      }, 30);
      setTimeout(() => {
        this.record.slice(8, 10).forEach((el, index) => {
          if (el.ws_id) {
            this.ws_id = el.ws_id;
            this.GetWsbinddevices(index + 8, this.ws_id);

            // this.d_id = el.ws_bind_d_ids.split(",")[0];
            // this.getVideo(index + 8);
          }
        });
      }, 40);
    },

    // 查询视频推流、拉流地址
    getVideo(i) {
      // this.ComeonlyWsid = [];
      const params = {
        ws_id: this.ws_id,
        d_id: this.d_id,
      };
      GetSiteVideo(params).then((res) => {
        if (res.code == 200) {
          // this.VideoCcomOnly = Array.from(new Set(this.VideoCcomOnly));
          // 关闭直播时使用
          this.ComeonlyWsid.push({
            ws_id: params.ws_id,
            d_id: params.d_id,
            com_only: res.data.com_only,
          });

          this.video = res.data;
          const url = res.data.video_url;
          const push_url = res.data.audio_url;
          this.video.stream_id = url.substring(url.lastIndexOf("/") + 1);
          this.video.push_stream_id = push_url.substring(
            push_url.lastIndexOf("/") + 1
          );
          // 显示最新快照
          const posterImgfilePath = document.getElementById("myVideo" + i);
          if (posterImgfilePath.poster != null) {
            posterImgfilePath.poster =
              urlConfig.baseUrl +
              "/files/picture/snapshot/snapshotpicture?filePath=" +
              res.data.file_path;
          }

          // 拉流
          this.videoPlayer(i);
          // 推流
          // this.talkStartTest();
          this.startPublish(
            this.stream_url + this.video.push_stream_id,
            new Audio()
          );
          let jingyin = document.getElementById("myVideo" + i);
          jingyin.muted = true;
          // // 获取摄像头状态
          // this.videoStatus("cycle");
        } else {
          // this.$message.error(res.data);
          if (res.data == "摄像头状态为：4") {
            this.$message.error("自动挂断");
          }
        }
        this.$nextTick(() => {
          // this.initSwiper();
        });
      });
    },

    // 每隔1秒检测一次摄像头状态
    checkStatus() {
      let that = this;
      if (that.cycleTime < that.cycleMax) {
        that.timer = setTimeout(() => {
          // that.videoStatus("cycle");
          that.cycleTime++;
        }, 1000);
      } else {
        that.$message.warn("直播查看超时");
        this.stopVideo();
      }
    },
    // 检测视频流状态
    videoStatus(type) {
      const params = {
        ws_id: this.ws_id,
        d_id: this.d_id,
        com_only: this.video.com_only,
      };
      CameraStatus(params).then((res) => {
        this.Video_Status = res.data;
        if (res.data === "2") {
          window.clearTimeout(this.timer); // 清理定时器
        } else {
          if (type !== "cycle") {
            this.$message.error("视频加载中");
          } else {
            this.checkStatus();
          }
        }
      });
    },
    // flv拉流播放视频
    videoPlayer(i) {
      // var videoElement = document.getElementById("myVideo");
      // this.startPlay(this.stream_url + this.video.stream_id, videoElement);
      // this.record.forEach((item, i) => {
      var videoElement = document.getElementById("myVideo" + i);
      this.startPlay(this.stream_url + this.video.stream_id, videoElement);
      // });
      // if (flvjs.isSupported()) {
      if (false) {
        var videoElement = document.getElementById("myVideo");
        this.flvPlayer = flvjs.createPlayer({
          type: "flv",
          // url: 'https://dsm.wisecommey.com:8843/live?app=stream&stream=' + this.video.stream_id,
          url:
            // "https://dsm.wisecommey.com:8843/stream/" +
            "https://dsm.ccom.com.cn:8843/stream/" +
            this.video.stream_id +
            ".flv",
          // url: 'http://123.56.117.58:8080/live/123456',
        });
        this.flvPlayer.attachMediaElement(videoElement); //将播放实例注册到video节点
        this.flvPlayer.load();
        this.flvPlayer.play();
        this.flvPlayer.on(
          flvjs.Events.ERROR,
          (errorType, errorDetail, errorInfo) => {
            console.log("errorInfo:", errorInfo);
          }
        );
        this.flvPlayer.on(flvjs.Events.STATISTICS_INFO, function (info) {
          console.log(
            "STATISTICS_INFO:",
            info,
            videoElement.currentTime,
            videoElement.readyState
          );
          if (info.speed !== 0) {
            if (this.decodedFrames === info.decodedFrames) {
              this.count++;
            } else {
              this.count = 0;
            }
            if (this.count == 5) {
              console.log("卡住了", videoElement.currentTime);
              videoElement.currentTime = videoElement.buffered.end(0) - 0.2;
            }
            this.decodedFrames = info.decodedFrames;
          }
        });

        this.timeout = setInterval(() => {
          if (videoElement.buffered.length) {
            let end = videoElement.buffered.end(0);
            let diff = end - videoElement.currentTime;
            console.log("diff:", diff);
            if (diff >= 4.0) {
              videoElement.currentTime = videoElement.buffered.end(0) - 0.2;
              console.log("跳帧");
            } else if (diff >= 2.0) {
              if (videoElement.playbackRate === 1.0) {
                videoElement.playbackRate = 1.5; // 采用加速方式追帧
                console.log("追帧(1.5倍速)");
              }
            } else {
              if (videoElement.playbackRate !== 1.0) {
                videoElement.playbackRate = 1.0;
                console.log("1.0速度");
              }
            }
          }
        }, 2000);
      }
    },
    startPlay(url, video) {
      this.player = new SrsRtcPlayerAsync(video);
      this.player
        .play(url)
        .then(function (session) {
          // console.log("sessionid", session.simulator, session.sessionid);
        })
        .catch(function (reason) {
          this.player.close();
          console.error(reason);
        })
        .finally(() => {});
      // console.log(this.player);
    },
    // 关闭
    close() {
      window.clearTimeout(this.timer); // 清理定时器
      this.ComeonlyWsid.forEach((item) => {
        console.log("ComeonlyWsid", item);
        this.video.com_only = item.com_only;
        this.ws_id = item.ws_id;
        this.d_id = item.d_id;
        this.GetWsbinddevices_did(item.ws_id, item);
        // setTimeout(() => {
        //   this.d_id = this.d_idClose;
        // console.log("this.d_id", this.d_id);

        //   this.offCall();
        //   this.stopVideo();
        // });
      });

      // });
    },

    stopVideo(ws_id, d_id, com_only) {
      const params = {
        ws_id: ws_id,
        d_id: d_id,
        com_only: com_only,
      };
      // this.talkEndTest(); //断开通话
      if (this.publisher) {
        this.publisher.close();
      }
      if (this.flvPlayer) {
        clearInterval(this.timeout);
        this.flvPlayer.unload(); //取消数据流加载
        this.flvPlayer.detachMediaElement(); //将播放实例从节点中取出
        this.flvPlayer.destroy(); //销毁播放实例
        this.flvPlayer = null;
      }
      // StopSiteVideo(params)
      //   .then((res) => {
      //     // this.$message.success("断开视频成功");
      //   })
      //   .catch(() => {
      //     // this.$message.error("断开视频失败");
      //   });
      const url =
        urlConfig.baseUrl +
        "/dsmcore/api/worksite/camera/unvideo?" +
        "ws_id=" +
        ws_id +
        "&d_id=" +
        d_id +
        "&com_only=" +
        com_only;
      // navigator.sendBeacon(url, params);
      const formData = new FormData();
      formData.append("ws_id", ws_id);
      formData.append("d_id", d_id);
      formData.append("com_only", com_only);
      fetch(url, {
        method: "POST",
        keepalive: true,
        headers: {
          token: sessionStorage.getItem("token"),
        },
      }).then((res) => {
        // console.log(res);
      });
    },
    stopVideo1() {
      const params = {
        ws_id: this.ws_id,
        d_id: this.d_id,
        com_only: this.video.com_only,
      };
      // this.talkEndTest(); //断开通话
      if (this.publisher) {
        this.publisher.close();
      }
      if (this.flvPlayer) {
        clearInterval(this.timeout);
        this.flvPlayer.unload(); //取消数据流加载
        this.flvPlayer.detachMediaElement(); //将播放实例从节点中取出
        this.flvPlayer.destroy(); //销毁播放实例
        this.flvPlayer = null;
      }
      // StopSiteVideo(params)
      //   .then((res) => {
      //     // this.$message.success("断开视频成功");
      //   })
      //   .catch(() => {
      //     // this.$message.error("断开视频失败");
      //   });
      const url =
        urlConfig.baseUrl +
        "/dsmcore/api/worksite/camera/unvideo?" +
        "ws_id=" +
        this.ws_id +
        "&d_id=" +
        this.d_id +
        "&com_only=" +
        this.video.com_only;
      // navigator.sendBeacon(url, params);
      const formData = new FormData();
      formData.append("ws_id", this.ws_id);
      formData.append("d_id", this.d_id);
      formData.append("com_only", this.video.com_only);
      fetch(url, {
        method: "POST",
        keepalive: true,
        headers: {
          token: sessionStorage.getItem("token"),
        },
      }).then((res) => {
        // console.log(res);
      });
    },
    handleCall() {
      this.voiceCall = !this.voiceCall;
      if (this.voiceCall) {
        this.onCall();
      } else {
        this.offCall();
      }
    },
    // 通话
    onCall() {
      const params = {
        ws_id: this.ws_id,
        d_id: this.d_id,
        com_only: this.video.com_only,
      };
      OnCall(params).then((res) => {
        console.log(res);
        if (res.code == "200" && res.data == "通话链接成功") {
          this.voiceCall = true;
          this.$message.success("通话中");
        } else {
          this.$message.success("通话忙");
        }
      });
    },
    // 通话结束
    offCall() {
      const params = {
        ws_id: this.ws_id,
        d_id: this.d_id,
        com_only: this.video.com_only,
      };
      OffCall(params).then((res) => {
        this.voiceCall = false;
      });
    },
    // 移动摄像机位置
    moveCamera(direction, directionCH) {
      const params = {
        ws_id: this.ws_id,
        d_id: this.d_id,
        com_only: this.video.com_only,
        direction: direction,
      };
      CameraMove(params).then((res) => {
        // this.$message.success("向" + directionCH + res.data);
      });
    },
    // 返回预置位
    reset() {
      const params = {
        ws_id: this.ws_id,
        d_id: this.d_id,
        com_only: this.video.com_only,
      };
      CameraReset(params).then((res) => {
        // this.$message.success(res.data);
        if (res.data == "摄像头复位设置成功") {
          this.$message.success("返回预置位成功");
        }
      });
    },
    handleRestart() {
      this.visible = true;
    },
    restartCancel() {
      this.visible = false;
    },
    // 重启摄像头
    restart() {
      this.confirmLoading = true;
      const params = {
        ws_id: this.ws_id,
        d_id: this.d_id,
        com_only: this.video.com_only,
      };
      CameraRestart(params)
        .then((res) => {
          this.$message.success("重启摄像头成功");
          this.confirmLoading = false;
          this.visible = false;
        })
        .catch(() => {
          this.$message.error("重启摄像头失败");
          this.confirmLoading = false;
        });
    },
    onPlayerPlay() {},
    onPlayerPause() {},

    //对讲-语音推流
    init(rec) {
      this.soundrecord = rec;
    },
    talkStartTest1() {
      let that = this;
      navigator.getUserMedia =
        navigator.getUserMedia || navigator.webkitGetUserMedia;
      if (!navigator.getUserMedia) {
        alert("浏览器不支持音频输入");
      } else {
        navigator.getUserMedia(
          {
            audio: true,
          },
          function (mediaStream) {
            that.init(new that.Recorder(mediaStream, that));
            console.log("开始对讲");
            that.useWebSocket();
          },
          function (error) {
            console.log(error);
            switch (error.message || error.name) {
              case "PERMISSION_DENIED":
              case "PermissionDeniedError":
                console.info("用户拒绝提供信息。");
                break;
              case "NOT_SUPPORTED_ERROR":
              case "NotSupportedError":
                console.info("浏览器不支持硬件设备。");
                break;
              case "MANDATORY_UNSATISFIED_ERROR":
              case "MandatoryUnsatisfiedError":
                console.info("无法发现指定的硬件设备。");
                break;
              default:
                console.info(
                  "无法打开麦克风。异常信息:" + (error.code || error.name)
                );
                break;
            }
          }
        );
      }
    },
    // 新版使用方法
    talkStartTest() {
      // 老的浏览器可能根本没有实现 mediaDevices，所以我们可以先设置一个空的对象
      if (navigator.mediaDevices === undefined) {
        navigator.mediaDevices = {};
      }
      // 一些浏览器部分支持 mediaDevices。我们不能直接给对象设置 getUserMedia
      // 因为这样可能会覆盖已有的属性。这里我们只会在没有getUserMedia属性的时候添加它。
      if (navigator.mediaDevices.getUserMedia === undefined) {
        navigator.mediaDevices.getUserMedia = function (constraints) {
          // 首先，如果有getUserMedia的话，就获得它
          var getUserMedia =
            navigator.webkitGetUserMedia || navigator.mozGetUserMedia;

          // 一些浏览器根本没实现它 - 那么就返回一个error到promise的reject来保持一个统一的接口
          if (!getUserMedia) {
            return Promise.reject(new Error("浏览器不支持getUserMedia"));
          }

          // 否则，为老的navigator.getUserMedia方法包裹一个Promise
          return new Promise(function (resolve, reject) {
            getUserMedia.call(navigator, constraints, resolve, reject);
          });
        };
      }

      let that = this;
      navigator.mediaDevices
        .getUserMedia({ audio: true, video: false })
        .then(function (stream) {
          that.init(new that.Recorder(stream, that));
          console.log("开始对讲");
          that.useWebSocket();
        })
        .catch(function (err) {
          // console.log(err.name + ": " + err.message);
        });
    },
    // 关闭对讲
    talkEndTest() {
      if (this.ws) {
        this.ws.close();
        this.soundrecord.stop();
        console.log("关闭对讲以及WebSocket");
      }
    },
    // 推流
    startPublish(url, audio) {
      this.publisher = new SrsRtcPublisherAsync(audio);
      // console.log('startPublish', this.publisher)
      const publisher = this.publisher;
      this.publisher.pc.onicegatheringstatechange = function (event) {
        if (publisher.pc.iceGatheringState === "complete") {
          //  $('#acodecs').html(SrsRtcFormatSenders(sdk.pc.getSenders(), "audio"))
          //  $('#vcodecs').html(SrsRtcFormatSenders(sdk.pc.getSenders(), "video"))
        }
      };
      this.publisher
        .publish(url)
        .then(function (session) {
          console.log("sessionid", session.simulator, session.sessionid);
          publisher.mute();
        })
        .catch(function (reason) {
          // Throw by sdk.
          if (reason instanceof SrsError) {
            if (reason.name === "HttpsRequiredError") {
              alert(
                `WebRTC推流必须是HTTPS或者localhost：${reason.name} ${reason.message}`
              );
            } else {
              alert(`${reason.name} ${reason.message}`);
            }
          }
          // See https://developer.mozilla.org/en-US/docs/Web/API/MediaDevices/getUserMedia#exceptions
          if (reason instanceof DOMException) {
            if (reason.name === "NotFoundError") {
              // alert(
              //   `找不到麦克风和摄像头设备：getUserMedia ${reason.name} ${reason.message}`
              // );
            } else if (reason.name === "NotAllowedError") {
              alert(
                `你禁止了网页访问摄像头和麦克风：getUserMedia ${reason.name} ${reason.message}`
              );
            } else if (
              [
                "AbortError",
                "NotAllowedError",
                "NotFoundError",
                "NotReadableError",
                "OverconstrainedError",
                "SecurityError",
                "TypeError",
              ].includes(reason.name)
            ) {
              alert(`getUserMedia ${reason.name} ${reason.message}`);
            }
          }
          publisher.close();
          // console.error(reason);
        });
    },
    Recorder(stream, that) {
      var sampleBits = 16; //输出采样数位 8, 16
      var sampleRate = 16000; //输出采样率
      var context = new AudioContext();
      var audioInput = context.createMediaStreamSource(stream);
      var recorder = context.createScriptProcessor(4096, 1, 1);
      var audioData = {
        size: 0, //录音文件长度
        buffer: [], //录音缓存
        inputSampleRate: 48000, //输入采样率
        inputSampleBits: 16, //输入采样数位 8, 16
        outputSampleRate: sampleRate, //输出采样数位
        oututSampleBits: sampleBits, //输出采样率
        clear: function () {
          this.buffer = [];
          this.size = 0;
        },
        input: function (data) {
          this.buffer.push(new Float32Array(data));
          this.size += data.length;
        },
        compress: function () {
          //合并压缩
          //合并
          var data = new Float32Array(this.size);
          var offset = 0;
          for (var i = 0; i < this.buffer.length; i++) {
            data.set(this.buffer[i], offset);
            offset += this.buffer[i].length;
          }
          //压缩
          var compression = parseInt(
            this.inputSampleRate / this.outputSampleRate
          );
          var length = data.length / compression;
          var result = new Float32Array(length);
          var index = 0,
            j = 0;
          while (index < length) {
            result[index] = data[j];
            j += compression;
            index++;
          }
          return result;
        },
        encodePCM: function () {
          //这里不对采集到的数据进行其他格式处理，如有需要均交给服务器端处理。
          var sampleRate = Math.min(
            this.inputSampleRate,
            this.outputSampleRate
          );
          var sampleBits = Math.min(this.inputSampleBits, this.oututSampleBits);
          var bytes = this.compress();
          var dataLength = bytes.length * (sampleBits / 8);
          var buffer = new ArrayBuffer(dataLength);
          var data = new DataView(buffer);
          var offset = 0;
          for (var i = 0; i < bytes.length; i++, offset += 2) {
            var s = Math.max(-1, Math.min(1, bytes[i]));
            data.setInt16(offset, s < 0 ? s * 0x8000 : s * 0x7fff, true);
          }
          return new Blob([data]);
        },
      };

      var sendData = function () {
        //对以获取的数据进行处理(分包)
        var reader = new FileReader();
        reader.onload = (e) => {
          var outbuffer = e.target.result;
          var arr = new Int8Array(outbuffer);
          if (arr.length > 0) {
            var tmparr = new Int8Array(1024);
            var j = 0;
            for (var i = 0; i < arr.byteLength; i++) {
              tmparr[j++] = arr[i];
              if ((i + 1) % 1024 == 0) {
                if (that.ws.readyState == 1) {
                  console.log(tmparr);
                  that.ws.send(tmparr);
                }
                if (arr.byteLength - i - 1 >= 1024) {
                  tmparr = new Int8Array(1024);
                } else {
                  tmparr = new Int8Array(arr.byteLength - i - 1);
                }
                j = 0;
              }
              if (i + 1 == arr.byteLength && (i + 1) % 1024 != 0) {
                if (that.ws.readyState == 1) {
                  that.ws.send(tmparr);
                }
              }
            }
          }
        };
        reader.readAsArrayBuffer(audioData.encodePCM());
        audioData.clear(); //每次发送完成则清理掉旧数据
      };

      this.start = function () {
        audioInput.connect(recorder);
        recorder.connect(context.destination);
      };

      this.stop = function () {
        recorder.disconnect();
      };

      this.getBlob = function () {
        return audioData.encodePCM();
      };

      this.clear = function () {
        audioData.clear();
      };

      recorder.onaudioprocess = function (e) {
        var inputBuffer = e.inputBuffer.getChannelData(0);
        audioData.input(inputBuffer);
        sendData();
      };
    },
    useWebSocket() {
      let that = this;
      this.ws = new WebSocket(
        // "wss://dsm.wisecommey.com:8443/ws/asset/" + that.video.push_stream_id
        "wss://dsm.ccom.com.cn:8443/ws/asset/" + that.video.push_stream_id
      );
      // this.ws = new WebSocket("wss://dsm.wisecommey.com:8443/ws/asset/123");
      this.ws.binaryType = "arraybuffer"; //传输的是 ArrayBuffer 类型的数据
      this.ws.onopen = function () {
        console.log("握手成功");
        console.log(that.ws);
        if (that.ws.readyState == 1) {
          //ws进入连接状态，则每隔500毫秒发送一包数据
          that.soundrecord.start();
        }
      };
      this.ws.onmessage = function (msg) {
        console.info(msg);
      };
      this.ws.onerror = function (err) {
        console.info(err);
      };
    },
    // 监听鼠标移入移出出现取消样板工地的按钮
    ShowCanceSampleSiteBTN(i, isShow, isTrue) {
      let CanceSampleSiteBtn = document.getElementById(
        "CanceSampleSiteBtn" + i
      );
      // let setSampleSiteBtn = document.getElementById("setSampleSiteBtn" + i);
      CanceSampleSiteBtn.style.display = isShow;
      CanceSampleSiteBtn.style.backgroundColor = "#00BFBF";
    },
    // 取消样板工地
    CanceSampleSite(item, type, i) {
      console.log("取消样板工地", item);
      const params = {
        ws_ids: item,
        ws_model: type,
      };
      // 1：取消样板工地   2：设置样板工地
      SetSampleSite(params).then((res) => {
        console.log(res);
        if (res.data == "设置成功") {
          // this.resData = res.data + i;
          let Cance_ed = document.getElementById("Cance_ed" + i);
          Cance_ed.style.display = "block";
          // let CanceSampleSiteBtn = document.getElementById(
          //   "CanceSampleSiteBtn" + i
          // );
          // let setSampleSiteBtn = document.getElementById(
          //   "setSampleSiteBtn" + i
          // );
          // CanceSampleSiteBtn.style.display = "none";
          // setSampleSiteBtn.style.display = "block";
          // this.isSampleSite = false;
          setTimeout(() => {
            Cance_ed.style.display = "none";
          }, 2000);
        }
      });
    },

    getTime() {
      this.timer = setInterval(() => {
        let timeDate = new Date();
        let year = timeDate.getFullYear();
        let mounth = timeDate.getMonth() + 1;
        mounth = mounth >= 10 ? mounth : "0" + mounth;
        let day = timeDate.getDate();
        day = day >= 10 ? day : "0" + day;
        let hours = timeDate.getHours();
        hours = hours >= 10 ? hours : "0" + hours;
        let minutes = timeDate.getMinutes();
        minutes = minutes >= 10 ? minutes : "0" + minutes;
        let seconds = timeDate.getSeconds();
        seconds = seconds >= 10 ? seconds : "0" + seconds;
        let week = timeDate.getDay();
        let weekArr = [
          "星期日",
          "星期一",
          "星期二",
          "星期三",
          "星期四",
          "星期五",
          "星期六",
        ];
        this.nowTime = `${year}-${mounth}-${day} ${hours}:${minutes}:${seconds} ${weekArr[week]}`;
      }, 1000);
    },
    clickFun() {
      this.isFullFlag = !this.isFullFlag;
      screenfull.toggle();
    },
    // 双击标题进行编辑
    dbTest() {
      this.isChecked = true;
      this.inputStr = this.TitleName;
    },
    inputStred() {
      this.TitleName = this.inputStr;
      // 编辑标题后保存到本地，下次打开页面还会存在
      localStorage.setItem("CaseFieldView_TitleName", this.TitleName);
      this.isChecked = false;
    },
    //  gb28181设备
    // gb28181设备直播
    getVideoGBS(index) {
      let that = this;
      let params = {
        gb28181: this.liveImei,
      };
      GBVideoLive(params).then((res) => {
        if (res.code == 200) {
          that.startPlayGBS(res.data.rtcs, index);
          this.gbVideoValue = res.data;
          this.startPlayGBSIndex.push(index);
        }
      });
    },
    startPlayGBS(zlmsdpUrl, index) {
      console.log("startPlayGBS", zlmsdpUrl, index);

      this.player = new ZLMRTCClient.Endpoint({
        element: document.getElementById("myVideo" + index), // video 标签
        debug: true, // 是否打印日志
        zlmsdpUrl: zlmsdpUrl, //SDP消息交换的服务器URL 流地址  rtcs
        simulcast: false, //是否启用多路复用
        useCamera: false, //使用摄像头
        audioEnable: true, //使用麦克风
        videoEnable: true, //指定是否仅接收媒体流（即作为接收端）。如果为true，则不会发送本地媒体流到远端。
        recvOnly: true, //是否仅接收媒体流
        resolution: { w: 735, h: 425 },
        usedatachannel: false,
      });
      // console.log("aaaaa", this.player);

      // this.player.start();
      let jingyin = document.getElementById("myVideo" + index);
      jingyin.muted = true;
      // 监听事件

      this.player.on(
        ZLMRTCClient.Events.WEBRTC_ICE_CANDIDATE_ERROR,
        function (e) {
          // ICE 协商出错
          console.log("ICE 协商出错", e);
        }
      );

      this.player.on(
        ZLMRTCClient.Events.WEBRTC_ON_REMOTE_STREAMS,
        function (e) {
          //获取到了远端流，可以播放
          console.log("播放成功", e.streams);
        }
      );

      this.player.on(
        ZLMRTCClient.Events.WEBRTC_OFFER_ANWSER_EXCHANGE_FAILED,
        function (e) {
          // offer anwser 交换失败
          // console.log("offer anwser 交换失败", e);
          stop();
        }
      );

      this.player.on(ZLMRTCClient.Events.WEBRTC_ON_LOCAL_STREAM, function (s) {
        // 获取到了本地流

        document.getElementById("myVideo").srcObject = s;
        document.getElementById("myVideo").muted = true;
        console.log("获取到了本地流", s);

        //console.log('offer anwser 交换失败',e)
      });

      this.player.on(ZLMRTCClient.Events.CAPTURE_STREAM_FAILED, function (s) {
        // 获取本地流失败
        // console.log("获取本地流失败");
      });

      this.player.on(
        ZLMRTCClient.Events.WEBRTC_ON_CONNECTION_STATE_CHANGE,
        function (state) {
          // RTC 状态变化 ,详情参考 https://developer.mozilla.org/en-US/docs/Web/API/RTCPeerConnection/connectionState
          // console.log("当前状态==>", state);
        }
      );

      this.player.on(
        ZLMRTCClient.Events.WEBRTC_ON_DATA_CHANNEL_OPEN,
        function (event) {
          // console.log("rtc datachannel 打开 :", event);
        }
      );

      this.player.on(
        ZLMRTCClient.Events.WEBRTC_ON_DATA_CHANNEL_MSG,
        function (event) {
          // console.log("rtc datachannel 消息 :", event.data);
          // document.getElementById("msgrecv").value = event.data;
        }
      );
      this.player.on(
        ZLMRTCClient.Events.WEBRTC_ON_DATA_CHANNEL_ERR,
        function (event) {
          // console.log("rtc datachannel 错误 :", event);
        }
      );
      this.player.on(
        ZLMRTCClient.Events.WEBRTC_ON_DATA_CHANNEL_CLOSE,
        function (event) {
          // console.log("rtc datachannel 关闭 :", event);
        }
      );
    },
    // 结束直播
    VideoUnliveGBS(index) {
      // let params = {
      //   // gb28181: imei,
      //   gb28181: this.liveImei,
      // };
      // GBVideoUnlive(params).then((res) => {
      //   // console.log("GBVideoUnlive", res);
      // });

      this.liveImeiList.forEach((el) => {
        const url =
          urlConfig.baseUrl + "/dsmcore/api/GB28181/unlive" + "gb28181=" + el;
        // navigator.sendBeacon(url, params);
        const formData = new FormData();
        formData.append("gb28181", el);
        fetch(url, {
          method: "POST",
          keepalive: true,
          headers: {
            token: sessionStorage.getItem("token"),
          },
        }).then((res) => {
          // console.log(res);
        });
        if (this.player) {
          var remote = document.getElementById("myVideo" + index);
          remote.pause();
          //关闭流
          this.player.close();
          this.player = null;
          // var local = document.getElementById("selfVideo");
          // local.srcObject = null;
          // local.load();
        }
      });
    },
  },
  // 指令
  directives: {
    myfoucs: {
      //自定义myfoucs指令
      update(el, binding) {
        if (binding.value) {
          el.focus();
        }
      },
    },
  },
  beforeDestroy() {
    // window.location.reload();
    if (this.player) {
      this.player.close();
    }
    if (this.publisher) {
      this.publisher.close();
    }
    this.close();

    // this.talkEndTest();
    clearInterval(this.getvideoSignalTimer);
    this.getvideoSignalTimer = null;
  },
  destroyed() {
    // window.removeEventListener("beforeunload");
  },
};
</script>

<style lang="less" scoped>
// 时间轴
video::-webkit-media-controls-timeline {
  display: none !important;
}
.xinhaoRed {
  color: red;
  font-size: 12px;
  margin: 0;
  padding: 0;
  margin-bottom: -25px;
}
@media screen and(min-width: 1400px) {
  .xinhaoRed {
    margin-bottom: -10px;
  }
}

.myVideo_out {
  // margin-top: 10px;
  width: 100%;
  height: 95%;
  padding: 10px;
  padding-right: 0;
  .myVideo_out1 {
    // width: 20%;
    width: 100%;
    height: 100%;
    // margin: 10px 0px 0px 0px;
    display: flex;
    flex-wrap: wrap;
    // height: 70%;
    // height: 65%;
    position: relative;
    margin-bottom: 3%;
    // margin-bottom: 70px;
  }

  // .myVideo {
  //   // display: flex;
  // }
}
.controlBtn {
  top: 0;
  right: 3%;
  color: white;
  width: 45%;
  z-index: 9;
  position: absolute;
  cursor: pointer;
}
.v-box-info {
  // position: absolute;
  // bottom: -11%;
  width: 97%;
  padding: 0 10px 5px 10px;
  text-align: left;
  background-color: #8ac6c3;
  z-index: 1;
  // height: 14%;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  // margin-bottom: 10px;
}
.v-box-title {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.w_name_jl_left {
  margin-left: 10px;
}
.Cance_ed {
  position: absolute;
  left: 40%;
  top: 50%;
  // padding: 10px 20px;
  // background-color: rgba(0, 0, 0, 0.4);
  color: #fff;
  z-index: 100;
  // display: none;
}
.v-container {
  background: #0a1621;
  min-width: 1200px;
  height: 100%;
  // height: 98%;
  // overflow: auto;
  // overflow: hidden;
}
.sub-container {
  // min-height: 100%;
  height: 5%;
  margin: 0 10px;
}
.v-title {
  position: relative;
  color: #66ffff;
  height: 75px;
  line-height: 75px;
  font-size: 27px;
  background: url("../../assets/images/visualize/title_inspection.png")
    no-repeat;
  background-position: center center;
  background-size: auto 100%;
  .v-title-left {
    position: absolute;
    left: 30px;
    color: #fff;
    font-size: 14px;
    line-height: 60px;
  }

  .v-title-right2 {
    display: flex;
    position: absolute;
    right: 7%;
    top: 0;
    color: #fff;
    font-size: 14px;
    line-height: 60px;
    cursor: pointer;
  }
  .quanping {
    &:hover {
      color: #66ffff;
    }
  }
}
.sub-buttom {
  height: 91%;
}
.test.editing .edit {
  display: block;
  width: 150px;
  margin-left: 40%;
}

.test.editing .view {
  display: none;
}

.test .edit {
  display: none;
  background-color: #062a49;
}
.Split {
  margin-right: 5px;

  /deep/ .ant-select-arrow {
    color: #fff;
  }
}
/deep/ .ant-select-selection {
  background-color: transparent;
  color: #fff;
  border: none;
}
// 轮播
.swiper-container {
  margin-top: 10px;
  width: 98%;
  height: 93%;
  background: #0a1621;
  .swiper-wrapper {
    // width: 100%;
    // height: 100%;
    .swiper-slide {
      height: 50%;
    }
  }
}
</style>
